.text-link {
    display: inline-block;
    color: $text-light;
    font-style: italic;
    text-decoration: none;
    text-transform: uppercase;
    @include fcb-extra-bold;
    transition: color .3s;

    @include hover {
        color: $primary-brand-yellow;

        svg {
            fill: $primary-brand-yellow;
            transform: translateX(.2rem);
        }
    }

    svg {
        display: inline-block;
        margin-left: .8rem;
        width: 1.2rem;
        height: .9rem;
        fill: #fff;
        transition: fill .3s, transform .3s;
    }

    &--dark {
        color: $text-primary;

        svg {
            fill: #222;
        }
    }

    &--medium {
        color: $primary-brand-blue;

        svg {
            fill: $primary-brand-blue;
        }
    }
}