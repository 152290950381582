.u-hide {
    // stylelint-disable-next-line declaration-no-important
    display: none !important;
}

.u-show {
    display: block;
}

.u-visually-hidden {
    visibility: hidden;
}

.u-fade-in {
    animation: .3s fadeIn ease-out forwards;
}

.u-screen-reader {
    position: absolute;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

/* Used to indent the widgets */
.u-indent {
    margin: 0 2.4rem;

    @media (max-width: $break-narrow) {
        margin: 0 1.6rem;
    }

    @media (max-width: $break-tablet) {
        margin: 0 .8rem;
    }

    @media (max-width: $break-mobile) {
        margin: 0 .4rem;
    }
}

.u-nav-docked {

    /* Dead CSS Class so article knows when nav is docked or not */
}

// stylelint-disable declaration-no-important
.u-center {
    display: table !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.u-vertical-center {
    top: 50% !important;
    transform: translateY(-50%) !important;
    position: relative !important;
}

.u-overflow-hidden {
    overflow: hidden;
}

.u-plain-background {
    position: relative;
    background: #fff;
}

.u-lazy-photo {
    opacity: 0;
    transition: opacity .4s;

    &.loaded {
        opacity: 1;
    }
}

@include mq($until: wide) {

    .u-hide-wide {
        display: none !important;
    }
}

@include mq($from: wide) {

    .u-show-wide {
        display: none !important;
    }
}

@include mq($until: desktop) {

    .u-hide-desktop {
        display: none !important;
    }
}

@include mq($from: desktop) {

    .u-show-desktop {
        display: none !important;
    }
}

@include mq($until: tablet) {

    .u-hide-tablet {
        display: none !important;
    }
}

@include mq($from: tablet) {

    .u-show-tablet {
        display: none !important;
    }
}

@include mq($until: phablet) {

    .u-hide-phablet {
        display: none !important;
    }
}

@include mq($from: phablet) {

    .u-show-phablet {
        display: none !important;
    }
}

@include mq($from: mobile) {

    .u-show-mobile {
        display: none !important;
    }
}

@include mq($until: mobile) {

    .u-hide-mobile {
        display: none !important;
    }
}
// stylelint-enable
