@include mq($until: $break-tablet) {

    .tertiary-navigation {
        color: $text-light;
        width: 100%;

        &__links {
            position: relative;
            left: -$secondary-nav-mobile-left-padding;
            width: calc(100% + #{$secondary-nav-mobile-left-padding});
            background-color: rgba($secondary-dark, .2);
            max-height: 0;
            transition: max-height .3s;
            overflow: hidden;
        }

        &__list-item {
            position: relative;
            padding-left: $secondary-nav-mobile-left-padding + 1rem;
            text-transform: capitalize;
            text-decoration: none;
            border-bottom: .1rem solid rgba(255, 255, 255, .05);

            &:last-of-type {
                border: none;
            }

            .secondary-navigation__menu-indicator {
                margin-top: 0;
                top: auto;
                transform: none;
            }
        }

        &__item {
            @include mobileDeepItem();
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            line-height: 5.6rem;

            &--dropdown {

                &::after {
                    position: absolute;
                    top: 50%;
                    right: 1.6rem;
                    content: '';
                    display: block;
                    background: rgba(255, 255, 255, .7);
                    width: .8rem;
                    height: .2rem;
                    opacity: 0;
                    transform: rotate(-90deg) translateY(-50%);
                    transform-origin: top;
                    transition: opacity .3s ease-out, transform .3s ease-out;
                }
            }
        }

        &--active {

            .tertiary-navigation {

                &__links {
                    max-height: 55rem;
                }

                &__item {

                    &::after {
                        transform: rotate(0deg);
                        opacity: 1;
                    }
                }
            }

            .tertiary-navigation__item--dropdown .secondary-navigation__menu-indicator {
                transform: rotate(90deg);
                opacity: 0;
            }
        }
    }
}
