/* Colours */

$text-primary: #222;
$text-secondary: #727272;
$text-tertiary: #c6c6c6;
$text-light: #fff;
$text-dark: #191635;

$primary-brand-red: #cd122d;
$primary-brand-red-lighter: #cf122d;
$primary-brand-red-darker: #ad1028;
$primary-brand-blue: #154284;
$primary-brand-yellow: #fdc52c;

$secondary: #8d1a17;
$secondary-dark: #181733;

$navigation-dark: #0a0927;
$navigation-border: #29283d;

$match-centre-dark: #0b0a22;
$match-complete-score-color: $primary-brand-blue;

$tertiary-green: #01a54b;
$tertiary-pink: $primary-brand-red; //Pink (ef2C57 was removed from branding, revert to primary red
$tertiary-orange: #f84c00;

$grey: #efefef;
$grey-light: #f8f8f8;
$grey-dark: #d8d8d8;
$border-grey: $grey;
$border-light: #fff;
$background-light: #fff;

$gradient-blue-red: linear-gradient(to right, $primary-brand-blue 0%, $primary-brand-red 100%);
$gradient-red-blue: linear-gradient(to right, $primary-brand-red 0%, $primary-brand-blue 100%);
$gradient-blue-red-vertical: linear-gradient(to bottom, $primary-brand-red 0%, $primary-brand-blue 100%);
$gradient-dark-blue-red-vertical: linear-gradient(to bottom, $secondary-dark 0%, $primary-brand-blue 20%, $primary-brand-red  92%, $secondary-dark 100%);
$gradient-dark-blue-red-vertical-fade: linear-gradient(to bottom, rgba($secondary-dark, .1) 0%, $primary-brand-blue 50%, $primary-brand-red 92%, $secondary-dark 100%);
$gradient-dark-blue-red-vertical-reverse: linear-gradient(to top, $secondary-dark 0%, $primary-brand-blue 20%, $primary-brand-red  100%);
$gradient-red-dark-blue-top-right: linear-gradient(45deg, $primary-brand-red 0%, $primary-brand-blue 70%);

$gradient-dark-blue-blue: linear-gradient(to top, $primary-brand-blue 0%, $secondary-dark 100%);
$gradient-dark-red-blue: linear-gradient(to right, darken($primary-brand-red, 10%) 0%, darken($primary-brand-blue, 10%) 100%);
$gradient-image-blue: linear-gradient(to bottom, rgba($secondary-dark, 0) 60%, $secondary-dark 100%);

$scroll-bar-width: 15px;

$hero-dark: #080e1f;

/* Videos */
$video-primary: #080e1f;
$video-primary-dark: #090c17;
$video-premium: #94825d;
$video-premium-dark: #79643e;
$cool-grey: #b2b7c4;

$video-border: #262b47;

$video-premium-gradient: linear-gradient(to right, rgba($video-premium, .9) 0%, rgba($video-premium-dark, .9) 100%);
$video-colllections-gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .95));

/* VIP */
$vip-primary: #bdb29a;
$vip-primary-dark: #94825d;
$vip-button-gradient: linear-gradient(to right, $vip-primary-dark, $video-premium-dark);
$vip-button-gradient-reverse: linear-gradient(to left, $vip-primary-dark, $video-premium-dark);
$vip-text-gradient: linear-gradient(to right, $vip-primary-dark, $vip-primary);
$vip-background: #0d0d10;
$vip-premium-background: #0d0d10;
$vip-premium-dark: #090c17;

/* Social Colours */
$facebook: #3b5998;
$facebook-dark: #2f4779;
$twitter: #55acee;
$twitter-dark: #1a8fe8;
$youtube: #b00;
$instagram: #e95950;
$instagram-gradient: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
$google: #db4437;
$google-dark: #b92d21;
$viber: #59267c;
$viber-dark: #471e63;
$viber-light: #665cac;
$whatsapp: #43d854;
$whatsapp-dark: #26bb37;
$linkcopy: #cf122d;
$linkcopy-dark: #a50e24;
$fbmessenger: #007fff;
$fbmessenger-dark: #0065cc;
$dugout: #efefef;
$dugout-dark: #727272;

/* Z-Index */
$level-none: -1;
$level-zero: 0;
$level-one: 10;
$level-two: 20;
$level-three: 30;
$level-four: 40;
$level-five: 50;
$level-six: 60;

$cookie-notice-height: 10rem;

/* Navigation Heights */
$primary-nav-height: 11.4rem;
$primary-nav-break-wide-height: 9.8rem;
$primary-nav-break-narrow-height: 8.2rem;
$primary-nav-break-tablet-height: 8.8rem;
$docked-nav-height: 4.8rem; // Also height of secondary nav in undocked mode
$survey-banner-height: 3.2rem;

$top-slim-nav-height: 3.6rem;

$logo-width: 32.8rem;
$logo-break-wide-width: 28.8rem;
$logo-break-narrow-width: 24rem;

$social-width: 29.6rem;
$social-break-wide-width: 24rem;
$social-break-narrow-width: 16rem;

$fcb-site-panel-width: 30.4rem;
$fcb-site-panel-break-narrow-width: 28rem;

$other-sports-secondary-nav-height: 16.4rem;
$other-sports-secondary-break-wide-nav-height: 14rem;
$other-sports-secondary-break-narrow-nav-height: 10.8rem;

$mc-side-col-width: 38rem;
$mc-side-col-width-small: 32rem;

/* Mobile navigation heights */
$main-mobile-height: 11rem;
$search-container-height: 6.8rem;
$main-mobile-logo-height: 4.4rem;
$bottom-user-bar-height: 4.4rem;
$mobile-padding-removal: 1.6rem;

/* Mobile navigation padding */
$secondary-nav-mobile-left-padding: 1.4rem;

/* Breakpoints */
$break-mobile: 400px;
$break-phablet: 640px;
$break-tablet: 840px;
$break-micro: 1000px;
$break-small: 1025px;
$break-narrow: 1100px;
$break-wide: 1280px;

/* Layout */
$layout-padding: 0;
$wrapper-width: 148rem;

/* Animations */
$article-transition-delay: .5s;

/* Photo ratios */
$ratio-one-to-one: 100%;
$ratio-sixteen-to-six: 37.5%;
$ratio-sixteen-to-seven: 43.8%;
$ratio-sixteen-to-ten: 62.5%;

$site-resource-prefix: '../'
