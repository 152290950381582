// stylelint-disable at-rule-empty-line-before

/* SETTINGS
========================================================================== */

@import 'includes/overrides/normalize';

@import 'includes/global/variables';

@import 'includes/global/mixins';

/* LAYOUT
========================================================================== */

@import 'includes/layout/mq';

@import 'includes/layout/grids';

/* GLOBAL
========================================================================== */

@import 'includes/global/fonts';

@import 'includes/global/global';

@import 'includes/global/typography';

@import 'includes/global/animations';

/* COMPONENTS
========================================================================== */

@import 'includes/components/buttons';

@import 'includes/components/text-link';

/* WIDGETS
========================================================================== */

// navigation

@import '../widgets/sites/fcb/navigation/styles/core';

@import '../widgets/sites/fcb/navigation/styles/language-switcher';

@import '../widgets/sites/fcb/navigation/styles/middle';

@import '../widgets/sites/fcb/navigation/styles/mobile-menu-close';

@import '../widgets/sites/fcb/navigation/styles/mobile-quick-nav';

@import '../widgets/sites/fcb/navigation/styles/secondary-navigation';

@import '../widgets/sites/fcb/navigation/styles/social';

@import '../widgets/sites/fcb/navigation/styles/tertiary-navigation';

@import '../widgets/sites/fcb/navigation/styles/top-slim-nav';

@import '../widgets/sites/fcb/navigation/styles/search-bar';

@import '../widgets/sites/fcb/navigation/styles/mega-dropdown';

// footer

@import '../widgets/sites/fcb/footer/styles/container';

@import '../widgets/sites/fcb/footer/styles/corporate-links';

@import '../widgets/sites/fcb/footer/styles/sponsors';

@import '../widgets/sites/fcb/social/styles/social-accounts';

// biscuit notice

@import "/Users/anthonygoodier/Documents/repositories/barcelona-web/src/widgets/sites/fcb/cookie-notice/styles/_cookie-notice.scss";
@import "/Users/anthonygoodier/Documents/repositories/barcelona-web/src/widgets/sites/fcb/cookie-notice/styles/_cookie-toggle.scss";

/* UTILITIES
========================================================================== */

@import "includes/utilities/_trumps.scss";

// stylelint-enable
.external-use {

    .live-match,
    .footer__trophies,
    .footer__sitemap,
    .user-options,
    .middle-navigation__item--search,
    .search-bar,
    .secondary-navigation__links,
    .middle-navigation__dropdown,
    .middle-navigation__item .middle-navigation__icon:not( .middle-navigation__icon--nike ) {
        display: none;
    }
}

@media (min-width: $break-tablet + 1) {

    .external-use .navigation__secondary {
        display: none;
    }
}
