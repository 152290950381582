.cookie-notice {
    background-color: #fff;
    z-index: $level-six;
    width: 100%;
    transition: .3s ease-out top;
    display: none;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed;
    top: auto;
    bottom: 0;
    box-shadow: 0 0 15rem rgba(0, 0, 0, .4);

    .button {
        max-height: 3.2rem;
        z-index: $level-one;
        margin: 0 .4rem;
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 9rem;
        background: radial-gradient(rgba(255, 255, 255, .5) 0%, transparent 73%);
        opacity: .4;
        pointer-events: none;
        top: -6rem;
    }

    &__heading {
        font-size: 3.2rem;
        @include gradient-title;
        display: inline-block;
        line-height: 1.1;
    }

    &__text-wrap {
        max-width: 79.6rem;
        padding: 4.4rem 1.6rem 5rem;
    }

    &__link {
        min-width: 16rem;
        text-align: center;
    }

    &__text {
        @include fcb-light;
        display: inline-block;
        text-align: left;
        color: #111;
        user-select: none;
        opacity: .8;
        font-size: 1.8rem;
        line-height: 2.3rem;
        padding: 1.1rem 0 3.2rem;
    }

    &--show {
        display: flex;
    }
}

@media (max-width: $break-tablet) {

    .cookie-notice {

        .button {
            font-size: 1rem;
        }

        &__heading {
            font-size: 2.4rem;
        }

        &__text {
            font-size: 1.4rem;
            line-height: 2rem;
            padding-bottom: 2.4rem;
        }

        &__link {
            min-width: auto;
            font-size: 1.3rem;
            margin-left: .5rem;
        }

        &__text-wrap {
            padding-top: 1.6rem;
            padding-bottom: 2.4rem;
        }
    }
}
