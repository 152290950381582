$widget-layout-padding-desktop: 5rem 2.4rem 4.4rem;
$widget-layout-padding-phablet: 1rem 1.2rem;

/* Grid */
.wrapper {
    width: 100%;
    max-width: $wrapper-width;
    margin: 0 auto;
    padding: 0 $layout-padding;
    position: relative;
    @include clearfix;
}

/* Columns */

//stylelint-disable-next-line rule-empty-line-before, selector-max-attribute, string-quotes
[class*='col-'] {
    width: 100%;
    padding-left: $layout-padding;
    padding-right: $layout-padding;
    float: left;
}

$i: 1;

@while $i < 13 {

    .col-#{$i} {
        width: ( 100% / 12 ) * $i;
    }
    $i: $i + 1;
}

@include mq($until: wide) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-wide {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

@include mq($until: desktop) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-desk {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

@include mq($until: tablet) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-tab {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

@include mq($until: phablet) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-phab {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

@include mq($until: mobile) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-mob {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

/* Block Grids */

//stylelint-disable-next-line rule-empty-line-before, selector-max-attribute, string-quotes
[class*='block-list'] {
    clear: both;

    //stylelint-disable-next-line rule-empty-line-before, selector-max-attribute
    > li {
        display: inline-block;
        vertical-align: top;
        margin-right: -4px;
        clear: both;
        margin-bottom: $layout-padding * 2;
        padding-left: $layout-padding;
        padding-right: $layout-padding;
        width: 100%;
    }
}

@include mq($from: mobile) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i} > li {
            width: ( 100 / $i ) * 1%;
            // reset clear from larger breakpoints
            &:nth-child( n ) {
                clear: none;
            }

            &:nth-child( #{ $i }n + 1 ) {
                clear: left;
            }
        }
        $i: $i + 1;
    }
}

@include mq($until: wide) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-wide > li {
            width: ( 100 / $i ) * 1%;
            // reset clear from larger breakpoints
            &:nth-child( n ) {
                clear: none;
            }

            &:nth-child( #{ $i }n + 1 ) {
                clear: left;
            }
        }
        $i: $i + 1;
    }
}

@include mq($until: desktop) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-desk > li {
            width: ( 100 / $i ) * 1%;
            // reset clear from larger breakpoints
            &:nth-child( n ) {
                clear: none;
            }

            &:nth-child( #{ $i }n + 1 ) {
                clear: left;
            }
        }
        $i: $i + 1;
    }
}

@include mq($until: tablet) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-tab > li {
            width: ( 100 / $i ) * 1%;
            // reset clear from larger breakpoints
            &:nth-child( n ) {
                clear: none;
            }

            &:nth-child( #{ $i }n + 1 ) {
                clear: left;
            }
        }
        $i: $i + 1;
    }
}

@include mq($until: phablet) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-phab > li {
            width: ( 100 / $i ) * 1%;
            // reset clear from larger breakpoints
            &:nth-child( n ) {
                clear: none;
            }

            &:nth-child( #{ $i }n + 1 ) {
                clear: left;
            }
        }
        $i: $i + 1;
    }
}

@include mq($until: mobile) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-mob > li {
            width: ( 100 / $i ) * 1%;
            // reset clear from larger breakpoints
            &:nth-child( n ) {
                clear: none;
            }

            &:nth-child( #{ $i }n + 1 ) {
                clear: left;
            }
        }
        $i: $i + 1;
    }
}
