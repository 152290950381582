@include mq($from: $break-tablet) {

    .top-slim-nav {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: space-between;

        &__fcb-sites-nav {
            position: relative;
            height: 100%;
        }

        &__right-hand-row {
            display: flex;
            align-items: center;
        }

        &__social {
            height: 100%;
            background-color: $navigation-dark;
        }

        &__user-actions {
            display: flex;
            height: 100%;
        }
    }
}

@include mq($until: $break-tablet) {

    .top-slim-nav {
        display: flex;
        height: 100%;

        &__right-hand-row {
            width: 100%;
            height: 100%;
        }

        &__social {
            display: none;
        }

        &__user-actions {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: space-around;
            align-items: center;
            background: $navigation-dark;
            border-top: .1rem solid rgba(255, 255, 255, .2);

            .user-options,
            .language-switcher {
                width: 100%;
            }

            .language-switcher {
                justify-content: flex-end;
            }

            .user-options {
                position: relative;
                flex: 0;
            }
        }

        .icon--mobile {
            fill: #fff;
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .8rem;
        }

        @media (max-width: 360px) {

            .icon--mobile {
                margin-right: .6rem;
            }
        }
    }
}
