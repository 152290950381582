.search-bar {

    &__input {

        &::-ms-clear {
            cursor: pointer;
        }
    }
}

@include mq($from: $break-tablet) {

    .search-bar {
        position: absolute;
        height: 8.8rem;
        width: 100vw;
        margin-left: -$logo-width;
        background: #fff;
        z-index: $level-none;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transform: translateY(-100%);
        transition: opacity .3s, transform .3s, box-shadow .3s ease;

        &--hide-icon {

            .search-bar {

                &__icon {
                    opacity: 0;
                }
            }
        }

        &--open {
            opacity: 1;
            transform: translateY(0);
            box-shadow: 0 5rem 10rem 2rem rgba(0, 0, 0, .7);
        }

        &__cta {
            padding: 0;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            border-radius: 1.6rem;
            border: .1rem solid $grey;
            height: 3.2rem;
            width: 10.2rem;
            color: $text-primary;
            font-size: 1.2rem;
            cursor: pointer;
            background-color: transparent;
            transition: background-color .3s, border .3s, color .3s;
            line-height: 3.2rem;

            @include hover {
                background-color: $primary-brand-blue;
                color: $text-light;
            }
        }

        &__icon {
            padding: 0 .8rem;
            opacity: 1;
            transition: opacity .7s;

            svg {
                width: 3.2rem;
                height: 3.2rem;
                fill: #000;
            }
        }

        &__input {
            padding: 0 10% 0 1.6rem;
            border: none;
            font-size: 1.6rem;
            background: none;
            color: $text-primary;
            @include fcb-bold;
            width: 50%;

            &::placeholder {
                @include fcb-light;
                color: $text-primary;
                font-style: italic;
                opacity: .7;
            }
        }
    }

    @media (max-width: $break-wide) {

        .search-bar {
            height: 8rem;
            margin-left: -$logo-break-wide-width;
        }
    }

    @media (max-width: $break-narrow) {

        .search-bar {
            height: 6.4rem;
            margin-left: -$logo-break-narrow-width;
        }
    }

    @media (min-width: 1000px) and (max-width: $break-narrow) {

        .search-bar {
            margin-left: calc(-#{$logo-break-narrow-width} - 2.5rem);
        }
    }

    @media (max-width: 1000px) {

        .search-bar {
            margin-left: calc(-#{$logo-break-narrow-width} - 2rem);
        }
    }
}

@include mq($until: $break-tablet) {

    .search-bar {
        position: absolute;
        top: .8rem;
        left: .8rem;
        height: 4.4rem;
        width: 75vw;
        display: flex;
        align-items: center;
        border-radius: 2.2rem;
        background: rgba(255, 255, 255, .1);
        border: .1rem solid rgba(255, 255, 255, .1);
        padding: 0 1.6rem;

        &__cta {
            display: none;
        }

        &__icon {
            position: relative;
            top: .2rem;

            svg {
                width: 1.8rem;
                height: 1.8rem;
                fill: #fff;
            }
        }

        &__input {
            width: 100%;
            background-color: transparent;
            border: none;
            margin-left: .8rem;
            font-size: 1.6rem;
            line-height: 1.8rem;
            color: $text-light;
            @include fcb-bold;
            @include truncate();

            &::placeholder {
                @include fcb-light;
                color: rgba(255, 255, 255, .8);
                font-size: 1.4rem;
                line-height: 1.6rem;
                font-style: italic;
            }
        }
    }
}
