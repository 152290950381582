@include mq($from: $break-tablet) {

    .top-slim-social {
        display: flex;
        align-items: center;
        width: $social-width;
        height: 100%;
        justify-content: space-around;

        &__item {

            svg {
                width: 2rem;
                height: 2rem;
                fill: rgba(#fff, .6);
            }

            @include hover {

                .icon {
                    fill: #fff;
                }
            }

            &--title {
                @include fcb-bold;
                height: 1.2rem;
                color: rgba($text-light, .6);
                font-size: 1rem;
                line-height: 1.2rem;
                text-transform: uppercase;
                cursor: default;
                user-select: none;
            }
        }
    }

    @media (max-width: $break-wide) {

        .top-slim-social {
            width: $social-break-wide-width;

            &__item {

                svg {
                    width: 1.6rem;
                    height: 1.6rem;
                }
            }
        }
    }

    @media (max-width: $break-narrow) {

        .top-slim-social {
            width: $social-break-narrow-width;

            &__follow-context {
                display: none;
            }

            &__item {

                svg {
                    width: 1.4rem;
                    height: 1.4rem;
                }
            }
        }
    }
}

@include mq($until: $break-tablet) {

    .top-slim-social {
        display: none;
    }
}
