.mega-dropdown {
    position: absolute;
    display: flex;
    top: $primary-nav-height;
    left: 0;
    right: 0;
    padding: 3.5rem 2.4rem 4rem;
    height: auto;
    background: $navigation-dark;
    justify-content: center;
    cursor: default;
    @include dropdownHover(top center);

    &__column {
        padding: .8rem 3.6rem 0;
        text-align: left;
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform .3s .2s ease, opacity .3s .1s ease;

        &--title {
            padding-top: 0;
            padding-right: 6rem;
            font-size: 2.2rem;
            color: $primary-brand-yellow;
            @include fcb-extra-bold;
            text-transform: none;
        }
    }

    &__list-item {
        display: block;
        position: relative;

        &--title {
            margin-bottom: 1rem;
            color: $primary-brand-yellow;
        }

        &--active::before {
            content: '';
            position: absolute;
            top: .5rem;
            left: -.8rem;
            bottom: .5rem;
            width: .3rem;
            background: $primary-brand-yellow;
        }
    }

    &__list-link {
        display: block;
        padding: .6rem 0;
        color: $text-light;
        text-decoration: none;
        font-size: 1.3rem;
        line-height: 1.6rem;
        text-transform: none;
        @include fcb-semi-bold;

        @include hover {
            text-decoration: underline;
        }
    }
}

@media (max-width: $break-wide) {

    .mega-dropdown {
        top: $primary-nav-break-wide-height;

        &__column {
            padding: 1.2rem 2.4rem 0;

            &--title {
                padding: 0 2.4rem;
            }
        }
    }
}

@media (max-width: $break-narrow) {

    .mega-dropdown {
        padding: 2.4rem 2.4rem 3rem;
        top: $primary-nav-break-narrow-height;

        &__column {
            padding-top: 1rem;

            &--title {
                padding-top: 0;
                font-size: 1.8rem;
            }
        }
    }
}

@media (max-width: $break-small) {

    .mega-dropdown {
        padding-top: 1.8rem;
        top: 4.5rem;
        flex-wrap: wrap;
        justify-content: space-between;

        &__column {
            padding: 1.6rem 1.6rem 0;

            &--title {
                width: 100%;
                padding: 0;

                & + .mega-dropdown__column {
                    padding-left: 0;
                }
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
}