.cookie-toggle {
    max-width: 66.8rem;
    margin: 0 auto;
    padding-bottom: 2.4rem;
    text-align: left;

    &__container {
        display: grid;
        grid-template-columns: auto 1fr;
    }

    &__heading {
        display: inline;
        grid-column: 1;
        align-self: center;
        margin-right: 1.6rem;
        text-align: left;

        &:nth-child( 3n )::before {
            // IE11 grid fallback
            content: '';
            display: block;
        }
    }

    &__buttons {
        display: inline-block;
        grid-column: 2;
        margin: .8rem;
    }
}

@media (max-width: $break-phablet) {

    .cookie-toggle {
        text-align: center;

        &__heading {
            display: block;
            text-align: center;
            margin-right: 0;
        }
    }
}