.footer-corporate-links {

    &__bottom-container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        > div {
            flex: 1 0 auto;
        }
    }

    &__copyright {
        @include fcb-light;
        color: rgba(255, 255, 255, .75);
        height: 1.6rem;
        line-height: 1.6rem;
        font-size: 1.4rem;

        span {
            @include fcb-semi-bold;
            color: $text-light;
        }
    }

    &__group {
        display: inline-flex;
    }

    &__item {
        position: relative;
        padding: 0 .4rem;

        &::after {
            display: block;
            content: '';
            position: absolute;
            top: .6rem;
            right: 0;
            width: .1rem;
            height: 1.2rem;
            background-color: #fff;
            opacity: .75;
        }

        &:last-of-type {

            &::after {
                content: none;
            }
        }
    }

    &__item-link {
        @include fcb-light;
        color: $text-light;
        line-height: 1.4rem;
        font-size: 1.2rem;
        padding: .8rem;
        text-decoration: none;

        @include hover {
            text-decoration: underline;
        }
    }

    &__logo {
        margin-bottom: 3.2rem;

        .icon {
            fill: #fff;
            height: 3.2rem;
            width: 21.6rem;
        }
    }
}

@mixin footer-corporate-links-tablet {

    &__bottom-container {
        flex-direction: column;
    }

    &__copyright {
        font-size: 1rem;
        line-height: 1.2rem;
        padding-left: .8rem;
    }

    &__group {
        margin-top: 1.2rem;
        flex-direction: column;
    }

    &__item {
        padding: .4rem;

        &::after {
            content: none;
        }
    }
}

.footer--narrow {

    .footer-corporate-links {
        @include footer-corporate-links-tablet;
    }
}

@media (max-width: $break-tablet ) {

    .footer-corporate-links {
        @include footer-corporate-links-tablet;
    }
}
