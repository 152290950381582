$crest-size: 5.4rem;

@include mq($from: $break-tablet) {

    .secondary-navigation {
        width: 100%;
        height: 4.8rem;
        display: flex;
        background: $gradient-red-blue;
        transition: height .2s ease;

        &__background {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: -9999px;
            opacity: 1;
            transition: opacity .2s ease;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &__bottom-scroll-indicator {
            display: none;
        }

        &__list-item {
            height: $docked-nav-height;

            &--title {
                display: none;
            }

            &--more {
                @include hover($break-tablet, false) {

                    .flex-nav {
                        visibility: visible;
                    }
                }
            }
        }

        &__link-panel {
            width: 100%;
            display: flex;
            align-items: center;

            &--hidden {
                display: none;
            }

            // stylelint-disable-next-line rule-empty-line-before, selector-max-attribute
            &[data-menu-format='mobile-only-extra'] {
                display: none;
            }

            div {
                position: relative;
                display: flex;
                align-items: center;
                margin: 0 2.4rem 0 auto;
                color: $text-light;
                cursor: pointer;

                &::before {
                    width: calc(100% - 3.2rem);
                    left: -3.2rem;
                }
            }

            .more-label {
                display: flex;
                align-items: center;

                .icon {
                    height: .64rem;
                    width: .64rem;
                    fill: #fff;
                    margin: 0 .4rem;
                }
            }

            .section-navigation__text-container-more--open .more-label {
                color: $text-light;
            }
        }

        &__section {
            margin: 0 5.6rem 0 3rem;
            font-size: 1.8rem;
            line-height: 2rem;
            color: $text-light;
            @include fcb-extra-bold;
            text-decoration: none;
            white-space: nowrap;
            opacity: 0;
            transition: opacity .2s;

            &--show {
                opacity: 1;
            }
        }

        &__links {
            width: 100%;
            display: flex;
        }

        &__item-container {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: $text-light;
            height: 100%;
            margin: 0 2.4rem;
            font-size: 1.4rem;
            white-space: nowrap;
            cursor: pointer;
            user-select: none;
            text-decoration: none;
            text-transform: capitalize;

            &::before {
                content: '';
                height: 0;
                display: block;
                position: absolute;
                bottom: 0;
                left: -.6rem;
                right: -.6rem;
                margin: 0 auto;
                background: #fff;
                transition: height .1s ease-in;
            }

            @include hover {

                &::before {
                    height: .4rem;
                }
            }

            &--active {

                &::before {
                    background: $primary-brand-yellow;
                    height: .4rem;
                }
            }
        }

        &__menu-indicator {
            display: none;
        }

        .tertiary-navigation {
            display: none;
        }

        // stylelint-disable-next-line rule-empty-line-before, selector-max-id
        .flex-nav {
            @include dropdown;
            width: auto;
            left: auto;
            right: -2.4rem;
            margin: 0;
            border: none;
            background: $primary-brand-blue;
            box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, .08);

            .secondary-navigation {

                &__list-item {
                    transition: background-color .3s ease;

                    @include hover {
                        background-color: darken($primary-brand-blue, 10%);
                    }
                }

                &__item {
                    justify-content: flex-end;
                    padding: .8rem 0;

                    @include hover {

                        &::before {
                            content: none;
                        }
                    }

                    &:focus {

                        &::before {
                            content: '';
                        }
                    }
                }
            }

            &--open {
                visibility: visible;
            }
        }
    }

    @media (max-width: $break-wide) {

        .secondary-navigation {

            &__list-item {

                &--more {

                    .more-label {
                        width: 100%;
                        justify-content: flex-end;
                    }
                }
            }

            &__item {
                margin: 0 2.4rem;
            }

            &__logo {
                min-width: $logo-break-wide-width;
                max-width: $logo-break-wide-width;
            }
        }
    }

    @media (max-width: $break-narrow) {

        .secondary-navigation {

            &__list-item {

                &--more {

                    .more-label span {
                        font-size: 1.2rem;
                    }
                }
            }

            &__item {
                font-size: 1.2rem;
                margin: 0 2rem;
            }

            &__logo {
                min-width: $logo-break-narrow-width;
                max-width: $logo-break-narrow-width;
            }
        }
    }
}

@include mq($until: $break-tablet) {

    .secondary-navigation {
        height: calc(100% - #{$bottom-user-bar-height});
        margin-top: $search-container-height;
        overflow-y: auto;

        @keyframes bounce {

            from,
            20%,
            53%,
            80%,
            to {
                animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                transform: translate3d(0, 0, 0);
            }

            40%,
            43% {
                animation-timing-function: cubic-bezier(.755, .05, .855, .06);
                transform: translate3d(0, -10px, 0);
            }

            70% {
                animation-timing-function: cubic-bezier(.755, .05, .855, .06);
                transform: translate3d(0, -8px, 0);
            }

            90% {
                transform: translate3d(0, -1px, 0);
            }
        }

        &__bottom-scroll-indicator {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2rem;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 80%);
            text-align: center;
            transition: opacity .15s;
            opacity: 1;
            border-radius: .8rem;
            z-index: $level-one;

            svg {
                width: 100%;
                height: .8rem;
                margin-top: .6rem;
                margin-bottom: .2rem;
                transform-origin: center bottom;
            }

            &--hide {
                opacity: 0;
                height: 0;
                overflow: hidden;
            }

            &--bounce {

                svg {
                    animation: bounce 1s 1;
                    animation-delay: .75s;
                }
            }
        }

        &__logo-descriptor {
            display: none;
        }

        &__item-container {
            height: 100%;
        }

        &__section {
            display: none;
        }

        &__item {
            @include mobileDeepItem();
            margin: 0;
        }

        &__logo {
            position: absolute;
            width: 40vw;
            height: 0;
            left: calc(-50vw + 1.6rem);

            svg {
                width: 100%;
                height: 100%;
                fill: #fff;
            }
        }

        &__links {
            background: $gradient-blue-red-vertical;
            border-radius: .8rem 0 0 .8rem;
            min-height: calc(100% - #{$search-container-height} - #{$bottom-user-bar-height});
            height: 100%;
            overflow-y: auto;
            position: relative;
            transition: height .3s;

            &::after {
                display: block;
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
            }

            &--mobile-only-extra {
                background: $primary-brand-blue;

                .secondary-navigation {

                    &__list-item {
                        line-height: 7.2rem;
                        height: 7.2rem;
                    }

                    &__item {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        &::before {
                            height: 4.8rem;
                        }

                        &--mobile-social {
                            flex-direction: row;
                            align-items: center;

                            .secondary-navigation {

                                &__item-summary {
                                    font-size: 1.1rem;
                                }

                                &__social-content {
                                    display: flex;
                                    flex-direction: column;
                                    flex-grow: 1;
                                    height: 100%;
                                    justify-content: space-around;
                                }

                                &__social-icon {
                                    width: 3.2rem;
                                    height: 3.2rem;
                                    fill: #fff;
                                    margin-right: 1.6rem;
                                }
                            }
                        }
                    }

                    &__item-summary {
                        display: block;
                        width: 90%;
                        @include fcb-light;
                        line-height: 1.4rem;
                        height: 2.8rem;
                        overflow: hidden;
                        font-size: 1rem;
                        opacity: .8;
                    }

                    &__item-title {
                        @include fcb-bold;
                        text-transform: uppercase;
                        height: 1.2rem;
                        line-height: 1.2rem;
                        margin-top: .8rem;
                        margin-bottom: .4rem;
                    }

                    &__menu-indicator {
                        display: none;
                    }
                }
            }
        }

        &__link-panel {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: transform .1s ease;

            .secondary-navigation__list-item {
                opacity: 0;
                transform: translateX(100%);
                transition: opacity .3s ease, transform .3s ease;
                will-change: transform;
            }

            &--active {

                .secondary-navigation__list-item {
                    transform: translateX(0);
                    opacity: 1;

                    &--active {
                        @include fcb-bold;
                    }

                    @for $i from 1 through 16 {

                        &:nth-of-type( #{$i} ) {
                            transition-delay: (#{$i*.08s});
                        }
                    }
                }
            }

            &--hidden {
                overflow: hidden;
                transform: translateX(-200%);
                transition-delay: .3s;
            }
        }

        &__list-item {
            height: 5.6rem;
            line-height: 5.6rem;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: $secondary-nav-mobile-left-padding;
            text-transform: capitalize;
            text-decoration: none;

            &::after {
                content: '';
                width: 100%;
                height: .1rem;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: rgba(255, 255, 255, .1);
            }

            a {
                width: 100%;
                height: 5.6rem;
                line-height: 5.6rem;
                color: $text-light;
                font-size: 1.3rem;
                text-decoration: none;
            }

            .tertiary-navigation__links .tertiary-navigation__item {
                height: 4.4rem;
            }

            .tertiary-navigation {
                display: none;
            }

            &--dropdown {
                height: auto;
                line-height: 0;

                svg {
                    margin-top: .8rem;
                }

                .tertiary-navigation {
                    display: block;
                }

                .secondary-navigation__item {
                    display: none;
                }
            }

            &--title {
                height: 4rem;
                justify-content: flex-start;
                background: rgba(0, 0, 0, .4);
                color: $text-light;
                @include fcb-extra-bold;
                border-radius: .8rem 0 0;
                text-transform: uppercase;

                svg {
                    margin-right: .8rem;
                    fill: #fff;
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }

            &--icon {
                padding-left: 1.2rem;
            }
        }

        &__item-title {
            height: 1.2rem;
            line-height: 1.2rem;
            text-transform: uppercase;
        }

        &__item-summary {
            display: none;
        }

        &__menu-indicator {
            width: .8rem;
            height: .8rem;
            position: relative;
            margin-right: 1.6rem;
            float: right;
            top: 50%;
            opacity: 1;
            fill: #fff;
            transform: translateY(-50%);
            transition: opacity .25s ease, transform .25s ease;
        }

        &__title-crest {
            width: 2.4rem;
            height: 2.4rem;
        }

        .tertiary-navigation {

            &__item {

                &--dropdown {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
