@mixin clearfix {

    &::before {
        content: '';
        display: block;
    }

    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

/*
 * When a full-width widget is inside a container, this mixin uses viewport width to break it out of that container
 * Note that overflow-x: hidden should be applied to to <body>, as the vw calculation does not take into account static scrollbar width
 */

@mixin full-width-widget {
    width: 100%;

    @supports (width: 100vw) {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        @media (max-width: $break-tablet) {
            width: auto;
            margin-left: -1.6rem;
            margin-right: -1.6rem;
            left: auto;
            right: auto;
        }

        @media (max-width: $break-phablet) {
            margin-left: -.8rem;
            margin-right: -.8rem;
        }
    }
}

/*
 * Adds hover and focus styles for tablet (by default) and up
 * Only keeps focus styles for below tablet (by default)
 */

@mixin hover($breakpoint: $break-tablet, $enableFocus: true, $disableFocusAtBreakpoint: false) {

    @if $enableFocus and not $disableFocusAtBreakpoint {

        &:focus {
            @content;
        }
    }

    @media (min-width: $breakpoint) {

        &:hover {
            @content;
        }

        @if $disableFocusAtBreakpoint {

            &:focus {
                @content;
            }
        }
    }
}

@mixin retina-bg($file, $type, $width, $height) {
    // stylelint-disable-next-line function-url-quotes
    background-image: url($file + '.' + $type);

    @media (-webkit-min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2) {

        & {
            // stylelint-disable-next-line function-url-quotes
            background-image: url($file + '@x2.' + $type);
            background-size: $width $height;
        }
    }
}

/*
 * Takes paramaters for filename and type
 * Outputs a background image along with the retina media query
 * No background size needed as cover is used
 */

@mixin retina-bg-cover($file, $type) {
    // stylelint-disable-next-line function-url-quotes
    background-image: url($file + '.' + $type);
    background-size: cover;
    background-repeat: no-repeat;

    @media (-webkit-min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2) {

        & {
            // stylelint-disable-next-line function-url-quotes
            background-image: url($file + '@x2.' + $type);
        }
    }
}

/*
 * Applies an ellipsis to text that overflows the width passed into the mixin
 * NB this ONLY works on single lines of text
 */

@mixin truncate($width: 100%) {
    width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*
 * Applies an ellipsis to text that overflows the amount of lines given
 *
 * max-height and oveflow hidden workaround for non-webkit browsers
 */

@mixin truncate-multiline($line: 2, $line-height: 1.8rem) {
    max-height: $line * $line-height;
    overflow: hidden;

    @supports (-webkit-line-clamp: $line) {
        // stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix
        display: -webkit-box;
        -webkit-line-clamp: $line;
        -webkit-box-orient: vertical;
        // stylelint-enable
    }
}

@mixin button-reset { //remove default browser styling of a button tag
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
  https://github.com/bfred-it/object-fit-images
*/

@mixin object-fit($fit: fill, $position: null) {
    // stylelint-disable-next-line property-no-vendor-prefix
    -o-object-fit: $fit;
    object-fit: $fit;

    @if $position {
        // stylelint-disable-next-line property-no-vendor-prefix
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
}

@mixin dropdown() {
    position: absolute;
    visibility: hidden;
    z-index: $level-three;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 100%;
    margin: 0 .8rem .8rem 0;
    box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, .08);
    border: .1rem solid $grey;
    cursor: pointer;
}

@mixin dropdownTriangle($color: #fff) {

    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 0;
        height: 0;
        bottom: 100%;
        right: .2rem;
        border: .8rem solid $color;
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        filter: drop-shadow(0 -.1rem 0 rgba(0, 0, 0, .08));
    }
}

@mixin dropdownHover($origin: top right) {
    visibility: visible;
    transform: scale(0);
    opacity: 0;
    transform-origin: $origin;
    transition: transform .2s ease, opacity .1s ease;

    > li {
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform .3s .2s ease, opacity .3s .1s ease;
    }
}

/** Used on mobile navigation to indicate whether the current item is active or not **/

@mixin mobileDeepItem() {

    &::before {
        display: block;
        position: absolute;
        left: 0;
        width: .4rem;
        height: 100%;
        background-color: $primary-brand-yellow;
    }

    &--active {

        &::before {
            content: '';
        }
    }
}

/** Used on gallery thumbnails or hero to simulate a pile of pictures **/

@mixin double-shadow-hero() {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 98%;
        height: .5rem;
        background: linear-gradient(to right, $secondary-dark, rgba($secondary-dark, .8) 50%, $secondary-dark 100%);
        position: absolute;
        bottom: 0;
        margin-bottom: -.5rem;
        left: 50%;
        transform: translateX(-50%);
    }

    &::after {
        content: '';
        display: block;
        width: 96%;
        height: .5rem;
        background: linear-gradient(to right, rgba($secondary-dark, .4), rgba($secondary-dark, .3) 50%, rgba($secondary-dark, .4) 100%);
        position: absolute;
        bottom: 0;
        margin-bottom: -1rem;
        left: 50%;
        transform: translateX(-50%);
    }
}

@mixin double-shadow-thumb() {
    position: relative;

    &::before { // top grey gallery shadow
        content: '';
        display: block;
        height: 1rem;
        position: absolute;
        bottom: -.5rem;
        left: 2.5%;
        right: 2.5%;
        background: #c6c6c6;
        transition: transform .15s .1s ease;
    }

    &::after { // bottom grey gallery shadow
        content: '';
        display: block;
        height: 1rem;
        position: absolute;
        bottom: -1rem;
        left: 5%;
        right: 5%;
        background: rgba(198, 198, 198, .5);
        transition: transform .15s .05s ease;
    }
}

/* Colour the title to use the barca gradient */

@mixin gradient-title( $background: linear-gradient(to right, $tertiary-pink, $primary-brand-blue), $fallbackColor: $primary-brand-red ) {
    color: $fallbackColor;

    @supports (-webkit-background-clip: text) or (-webkit-text-fill-color: transparent) {
        background: $background;
        // stylelint-disable-next-line property-no-vendor-prefix
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $text-light;
    }
}

/* Styling used to display fixture info (teams, badge, time/score) */

@mixin fixture-score-container() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin fixture-score($fontSize: 3.2rem, $lineHeight: 3.6rem) {
    height: 4rem;
    text-align: center;

    & > span {
        @include fcb-extra-bold;
        display: inline-block;
        height: 3.9rem;
        font-size: $fontSize;
        line-height: $lineHeight;
        text-align: center;
        color: $text-light;
        background: linear-gradient(to bottom, #2c4481 0%, $secondary-dark 100%);
        width: auto;
        min-width: 8rem;
        margin: 0 .1rem;
        padding: 0 1rem;
    }
}

@mixin fixture-match-length($textColor: $text-secondary) {
    margin-top: .2rem;
    line-height: 1.2rem;
    font-size: 1rem;
    text-align: center;
    @include fcb-semi-bold;
    color: $textColor;
}

@mixin form-abbreviation {
    position: relative;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: .4rem;
    margin: 0 .2rem;

    abbr {
        position: relative;
        display: block;
        left: -.1rem; /* Needed due to font style italic applied */
        top: 0;
        font-size: 1.2rem;
        color: $text-light;
        text-align: center;
        @include fcb-bold;
        font-style: italic;
        cursor: pointer;
    }

    &--draw,
    &--D {
        background: #727272;
    }

    &--loss,
    &--L {
        background: $primary-brand-red;
    }

    &--win,
    &--W {
        background: $tertiary-green;
    }
}

@mixin glow {
    @supports (filter: blur(50px)) {

        &::before {
            content: '';
            height: 10rem;
            background-image: linear-gradient(to right, $primary-brand-blue, $primary-brand-red, $primary-brand-yellow);
            filter: blur(50px);
            border-radius: 50%;
            z-index: $level-one;
        }
    }
}

@mixin logoAnimation {

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 5%;
        height: 0;
        transition: height .2s cubic-bezier(.86, 0, .07, 1);
    }

    &::before {
        top: .8rem;
        right: -1px;
        background-color: $primary-brand-red;
    }

    &::after {
        bottom: 1.5rem;
        left: 0;
        background-color: $primary-brand-blue;
    }

    @include hover {
        background-color: $navigation-border;

        &::before {
            height: 25%;
        }

        &::after {
            height: 40%;
        }
    }
}

@mixin staggered-transition($items: 10) { // adds an increasing transition delay to a list of items to stagger their transition
    @for $i from 1 through $items {

        &:nth-child( #{$i} ) {
            transition-delay: #{$i * .3}s;
        }
    }
}

@mixin staggered-transition-reverse($items: 10) { // adds an increasing transition delay to a list of items to stagger their transition
    @for $i from 1 through $items {

        &:nth-child( #{$items + 1 - $i} ) {
            transition-delay: #{$i * .3}s;
        }
    }
}
