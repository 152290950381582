// stylelint-disable font-weight-notation, declaration-no-important

//stylelint-disable font-family-name-quotes

@mixin fcb-light {
    font-family: fcb-light, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: normal !important;
}

@mixin fcb-regular {
    font-family: fcb-regular, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: normal !important;
}

@mixin fcb-bold {
    font-family: fcb-bold, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: normal !important;
}

@mixin fcb-semi-bold {
    font-family: fcb-semi-bold, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: normal !important;
}

@mixin fcb-extra-bold {
    font-family: fcb-extra-bold, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: normal !important;
}