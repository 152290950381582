@include mq($from: $break-tablet) {

    .middle-navigation {
        display: flex;
        height: 100%;
        border-left: .1rem solid $navigation-border;

        &__dropdown {
            @include dropdown();
            left: 50%;
            margin-top: -.8rem;
            width: auto;
            min-width: 100%;
            background-color: $primary-brand-blue;
            padding: .8rem 0;
            border: none;
            box-shadow: 0 .4rem .9rem 0 rgba(0, 0, 0, .2);
            @include dropdownHover(top center);
            @include dropdownTriangle($primary-brand-blue);
            transform: translateX(-50%) scale(0);

            &::after {
                left: 50%;
                right: auto;
                transform: translateX(-50%);
            }

            &--open {
                opacity: 1;
                transform: translateX(-50%) scale(1);

                > li {
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            &--two-columns {
                column-count: 2;

                &::before {
                    content: '';
                    position: absolute;
                    top: 1.4rem;
                    left: 50%;
                    bottom: 2rem;
                    width: .1rem;
                    background: rgba(255, 255, 255, .1);
                }
            }
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100%;
            width: 100%;
            background-color: $secondary-dark;
            border-right: .1rem solid $navigation-border;
            color: $text-light;
            text-align: center;
            font-size: 1.6rem;
            @include fcb-bold;
            cursor: pointer;
            user-select: none;
            text-transform: uppercase;
            transition: background-color .3s, color .3s;

            &:last-of-type {
                border-right: none;
            }

            .middle-navigation__search-icon,
            .middle-navigation__sub-item a,
            & > a {
                display: inline-flex;
                align-items: center;
                color: $text-light;
                text-decoration: none;
                height: 100%;
                width: 100%;
                justify-content: center;
                transition: background-color .2s ease;
            }

            .middle-navigation__sub-item a {
                justify-content: flex-start;
            }

            &--dropdown {
                padding: 0 .8rem;

                @include hover() {

                    .middle-navigation__dropdown {
                        opacity: 1;
                        transform: translateX(-50%) scale(1);

                        > li {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }
            }

            &--mega {
                position: static;

                &.middle-navigation__item--active {
                    padding-top: .6rem;
                    border-bottom: .6rem solid $primary-brand-yellow;

                    &::before {
                        content: none;
                    }
                }

                @include hover {

                    .mega-dropdown {
                        opacity: 1;
                        transform: scale(1);

                        &__column {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }
            }

            &--active,
            &--dropdown-disabled {
                cursor: default;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transition: background .2s ease-in;
                    height: .6rem;
                    background: $primary-brand-yellow;
                }

                .middle-navigation__dropdown {
                    opacity: 0;
                    width: 0;
                    height: 0;
                    padding: 0;
                    overflow: hidden;
                }
            }

            &--dropdown-disabled {
                cursor: default;

                svg {
                    display: none;
                }

                @include hover {
                    background-color: $secondary-dark;

                    .middle-navigation__dropdown {
                        visibility: hidden;
                    }
                }
            }

            &--mobile-only,
            &--mobile-only-extra {
                display: none;
            }

            /* For accessibility we disable this from hover so the user can hide/show via keyboard only */
            &:focus {
                background-color: $navigation-border;
            }

            &--search {
                width: 7.8rem;
                min-width: auto;
                flex: 1 0 auto;

                @include hover {
                    background-color: $navigation-border;
                }
            }

            .search-icon {
                width: 3.2rem;
                height: 3.2rem;
                position: relative;
                margin-left: 0;

                &__upper {
                    width: 2.3rem;
                    height: 2.3rem;
                    border-radius: 100%;
                    border: .2rem solid #fff;
                    transform: translate(0);
                    transition: transform .1s .1s ease;
                }

                &__middle,
                &__lower {
                    height: .2rem;
                    position: absolute;
                    background-color: #fff;
                    border-radius: .2rem;
                }

                &__middle {
                    width: 3.4rem;
                    top: .3rem;
                    right: .4rem;
                    transform: rotate(-45deg) scale(0);
                    transform-origin: top right;
                    opacity: 0;
                    transition: bottom .1s 0s ease, opacity .1s 0s ease, transform .2s 0s ease;
                }

                &__lower {
                    width: 1.2rem;
                    bottom: 0;
                    right: .1rem;
                    transform: rotate(45deg);
                    transform-origin: bottom right;
                    transition: bottom .1s 0s ease, right .1s 0s ease, width .2s 0s ease;
                }
            }

            .middle-navigation__icon {
                width: .64rem;
                height: .64rem;
                margin-left: .4rem;
                fill: #fff;
                transition: fill .3s;

                &--external {
                    width: 1rem;
                    height: 1rem;
                }

                &--nike {
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-right: .4rem;
                }
            }

            &.search-icon--open {
                background-color: $navigation-border;

                .search-icon {
                    opacity: .7;
                    transition: opacity .2s ease;

                    &__upper {
                        transform: translate(.2rem, .2rem) rotate(-45deg) rotateX(90deg);
                        transition-delay: s;
                    }

                    &__middle {
                        right: .4rem;
                        opacity: 1;
                        transform: rotate(-45deg) scale(1);
                        transition: bottom .1s .1s ease, opacity .2s .1s ease, transform .2s .1s ease;
                    }

                    &__lower {
                        width: 3.4rem;
                        bottom: .4rem;
                        right: .5rem;
                        transition-delay: .1s;
                    }
                }

                @include hover {

                    .search-icon {
                        opacity: 1;
                    }
                }
            }
        }

        &__sub-item {
            @include fcb-semi-bold;
            text-transform: none;
            font-size: 1.4rem;
            cursor: pointer;
            user-select: none;
            white-space: nowrap;

            @include hover {
                text-decoration: underline;
            }

            a {
                padding: .8rem 1.6rem;
            }
        }

        &--hover & {

            &__item:not( .middle-navigation__item--hovered ) {
                color: rgba(255, 255, 255, .5);

                > div,
                > a {
                    color: rgba(255, 255, 255, .5);
                }

                .middle-navigation__icon {
                    fill: rgba(255, 255, 255, .5);
                }
            }
        }
    }

    @media (max-width: $break-wide) {

        .middle-navigation {

            &__item {
                font-size: 1.2rem;

                .middle-navigation__icon {

                    &--search {
                        width: 2.4rem;
                        height: 2.4rem;
                    }
                }

                &--search {
                    width: 6.2rem;
                }
            }

            &__sub-item {
                font-size: 1.2rem;
            }
        }
    }

    @media (max-width: $break-narrow) {

        .middle-navigation {

            &__item {
                font-size: 1.08rem;

                &--search {
                    width: 4.8rem;
                }

                .search-icon {
                    width: 2.8rem;
                    height: 2.8rem;

                    &__upper {
                        width: 2.1rem;
                        height: 2.1rem;
                    }

                    &__middle {
                        width: 2.8rem;
                    }

                    &__lower {
                        width: 1rem;
                    }
                }

                &.search-icon--open {

                    .search-icon {

                        &__middle {
                            top: .3rem;
                        }

                        &__lower {
                            width: 2.8rem;
                            bottom: .4rem;
                        }
                    }
                }
            }

            &__sub-item {
                font-size: 1.04rem;
            }
        }
    }
}

@include mq($until: $break-tablet) {

    .middle-navigation {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;

        &__dropdown {
            display: none;
        }

        &__item {
            position: relative;
            @include fcb-bold;
            font-size: 1.4rem;
            line-height: 1.4rem;
            color: rgba(255, 255, 255, .6);
            padding: 1.5rem .8rem 1.5rem 1.6rem;
            text-transform: uppercase;
            order: 1;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
            }

            &::before {
                top: 0;
                width: .5rem;
                background: $primary-brand-yellow;
                transition: top .3s, bottom .3s, opacity .2s;
                opacity: 0;
            }

            &::after {
                bottom: 0;
                width: 90%;
                height: .1rem;
                background-color: rgba(255, 255, 255, .1);
            }

            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: rgba(255, 255, 255, .6);

                .middle-navigation {

                    &__icon {
                        display: none;
                        width: 1.2rem;
                        height: 1.2rem;

                        &--external {
                            display: block;
                            margin-left: .8rem;
                            width: 1.1rem;
                            height: 1.1rem;
                            fill: #fff;
                        }
                    }
                }
            }

            &--dropdown-disabled {
                position: relative;

                &::before {
                    opacity: 1;
                    top: 1.2rem;
                    bottom: 1.2rem;
                }
            }

            &--active {
                @include fcb-extra-bold;
                color: $text-light;
                background: linear-gradient(to right, #2c4481, $secondary-dark);

                &::before {
                    opacity: 1;
                    height: 100%;
                }

                &.middle-navigation__item--home-logo {

                    .icon {
                        fill: #fff;
                    }
                }
            }

            &--mobile-only-extra {
                padding: .8rem .8rem .8rem 1.6rem;
                @include fcb-regular;
                color: $text-light;

                &::after {
                    content: none;
                }

                &:last-child {
                    padding-bottom: 2.4rem;
                }
            }

            &--first-mobile-only-extra {
                margin-top: auto;
                padding-top: 1.6rem;
            }

            &--search {
                display: none;
            }

            &--home-logo {
                padding-left: 1.2rem;
                order: 0;
            }

            .icon {
                display: none;
            }
        }

        &__home-logo {

            .icon {
                display: block;
                width: 12.3rem;
                height: 1.8rem;
                fill: rgba(255, 255, 255, .6);
            }
        }
    }
}
