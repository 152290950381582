/* Typography */
body {
    @include fcb-regular;
}

h1 {
    font-size: 3.2rem;
    color: $text-primary;
    @include fcb-extra-bold;
}

h2 {
    font-size: 1.76rem;
}

h3 {
    font-size: 1.44rem;
}

h4 {
    font-size: 1.28rem;
}

h5 {
    font-size: 1.12rem;
}

h6 {
    font-size: .96rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include fcb-bold;
}

strong {
    @include fcb-bold;
}

.light {
    @include fcb-light;
}

.bold {
    @include fcb-bold;
}

.semi-bold {
    @include fcb-semi-bold;
}

.extra-bold {
    @include fcb-extra-bold;
}

p {
    color: $text-primary;
    line-height: 1.6;

    a {
        text-decoration: none;
        color: $text-primary;

        @include hover {
            text-decoration: underline;
        }
    }
}
