@keyframes bars-loading-animation {

    70% {
        transform: scale(.5);
    }
}

@keyframes fadeUp {

    0% {
        opacity: 0;
        transform: translateY(2%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutAndIn {

    0% {
        opacity: 1;
    }

    5% {
        opacity: 0;
    }

    50% {
        transform: translateY(-2%);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInOut {

    0% {
        opacity: .3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: .3;
    }
}

@keyframes loading-spinner {

    0%,
    80%,
    100% {
        box-shadow: 0 2.4rem 0 -1.3rem;
    }

    40% {
        box-shadow: 0 2.4rem 0 0;
    }
}

@keyframes pulse {

    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes scaleIn {

    0% {
        transform: scale3d(.5, .5, .5)
    }

    50% {
        transform: scale3d(1.1, 1.1, 1.1)
    }

    100% {
        transform: scale3d(1, 1, 1)
    }
}
