/* Resets */

// stylelint-disable-next-line rule-empty-line-before, selector-max-universal
* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 0;
}

figure {
    margin: 0;
}

// stylelint-disable-next-line rule-empty-line-before, selector-max-type
ol,
ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    // stylelint-disable-next-line rule-empty-line-before, selector-max-type
    li {
        list-style-type: none;
    }
}

/* Global */
html {
    font-size: 10px;
}

body {
    direction: ltr;
    min-height: 100vh;
    background: $secondary-dark;
    @include fcb-regular;
    color: $text-primary;
    font-size: 1.4rem;
    overflow-x: hidden;
    overflow-y: auto;
    transition: background .4s ease-in;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: antialiased;

    // stylelint-disable-next-line rule-empty-line-before, selector-no-qualifying-type
    &.modal-fade {
        background: rgba(0, 0, 0, .4);
        overflow-y: hidden;
    }

    // stylelint-disable selector-no-qualifying-type
    &.overflow-hidden {
        overflow: hidden;
    }
    // stylelint-enable
}

// stylelint-disable-next-line rule-empty-line-before, selector-max-universal
*:focus {
    outline: 0;
}

// stylelint-disable-next-line rule-empty-line-before, selector-no-qualifying-type
.icon {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    vertical-align: middle;
}

.body-content {
    opacity: 1;
    transform-origin: center;
    transition: transform .5s ease-out, opacity .4s ease-in, top .4s ease-out;
    z-index: $level-three;

    &--locked {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        z-index: $level-six;
    }

    &--shrink {
        opacity: .3;
        transform: scale(.925);
        transition: transform .3s ease-in, opacity .3s ease-in;
    }

    &--notice-visible {

        .match-centre {
            padding-top: $cookie-notice-height;
        }
    }

    @media (max-width: $break-tablet) {
        padding-top: 12rem;
    }
}

.no-scroll {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    visibility: hidden;
}

.visually-hidden-nav-item {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    visibility: hidden;
}

.results-hero,
.fixture-hero,
.match-centre,
.vip-premium {

    .icn-club-badge--default-opacity {
        opacity: .3;
    }
}

.webviewBody {

    .body-content {
        top: 0;

        .fixed-hero-bg {
            top: 0;
        }
    }

    .static-hero {
        padding-bottom: 0;
    }

    .static-hero__content {
        display: none;
    }
}
