.social-accounts {
    position: relative;
    text-align: center;

    &--has-button {

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 1.6rem;
            height: .1rem;
            background: rgba(255, 255, 255, .2);
        }
    }

    &__title {
        margin: 3.2rem 0 0;
        color: $text-light;
        font-size: 1.8rem;
        line-height: 2.7rem;
        @include fcb-bold;
    }

    &__list {
        display: flex; // IE11 fallback
        // stylelint-disable-next-line declaration-block-no-duplicate-properties
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        max-width: 100rem;
        margin: 1.6rem auto 2.4rem;
        text-align: left;
        flex-wrap: wrap;

        &::after {
            content: '';
            display: block;
            clear: left;
        }
    }

    &__account-container {
        display: block;
        margin: .4rem;
        max-width: 15.8rem;
        min-width: 10.4rem;
        height: 10.4rem;
        flex: 1 1 15%;
    }

    &__account {
        position: relative;
        display: block;
        height: 100%;
        padding: 1.2rem 1.2rem 1.3rem;
        text-decoration: none;
        z-index: $level-one;

        &::before {
            content: '';
            position: absolute;
            top: -.2rem;
            left: -.2rem;
            right: -.2rem;
            bottom: -.2rem;
            background: transparent;
            transition: background .3s;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        @include hover {

            &::before {
                background: #fff;
                z-index: $level-zero;
            }
        }

        &--viber::after {
            background: $viber-light;
        }

        &--facebook::after {
            background: $facebook;
        }

        &--twitter::after {
            background: $twitter;
        }

        &--instagram::after {
            background: $instagram-gradient;
        }

        &--youtube::after {
            background: $youtube;
        }

        &--dugout {
            background: $dugout;

            .social-accounts {

                &__icon {
                    fill: $dugout-dark;
                }

                &__content-name,
                &__content-handle {
                    color: $text-dark;
                }
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        z-index: $level-one;
    }

    &__icon {
        display: block;
        width: 2.8rem;
        height: 2.8rem;
        fill: #fff;
    }

    &__content-name {
        margin-bottom: .4rem;
        color: $text-light;
        font-size: 1rem;
        line-height: 1.2rem;
        @include fcb-bold;
    }

    &__content-handle {
        color: $text-light;
        font-size: 1.2rem;
        line-height: 1.4rem;
        @include fcb-bold;
    }

    &__link-container {
        position: relative;
        display: inline-block;
        padding: 0 1.8rem;
        background: $secondary-dark;
    }

    &__link {
        display: inline-block;
    }
}

@media (max-width: $break-tablet) {

    .social-accounts {

        &__list {
            grid-template-columns: 1fr 1fr 1fr;
        }

        &__account-container {
            max-width: none;
            flex: 0 1 30%;
        }
    }
}

@media (max-width: 440px) {

    .social-accounts {

        &__list {
            grid-template-columns: 1fr 1fr;
        }

        &__account-container {
            flex: 0 1 47%;
        }
    }
}