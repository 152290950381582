/* Default desktop navigation */

/* Utility class for more nav */
.u-find-nav-width {
    // stylelint-disable-next-line declaration-no-important
    position: absolute !important;
    bottom: 0;
    left: -200vh;
    visibility: hidden;
}

.navigation-fixed-container {
    z-index: $level-six;

    &--notice-visible {
        height: $primary-nav-height + $docked-nav-height + $cookie-notice-height;

        .navigation {
            top: $cookie-notice-height;
        }

        .cookie-notice {
            top: 0;
        }
    }
}

.survey-banner,
.navigation__core,
.navigation__secondary {
    backface-visibility: hidden;
    transform-style: preserve-3d;
}

.main-mobile-navigation {
    transition: top .3s ease-out;
    top: 0;
    position: relative;
}

@include mq($from: $break-tablet) {

    .navigation-fixed-container {
        position: relative;
        width: 100%;
        height: $primary-nav-height + $docked-nav-height + $survey-banner-height;
        z-index: $level-six;
        transition: .3s ease-out height;

        &--notice-visible {
            height: $primary-nav-height + $docked-nav-height + $cookie-notice-height;

            .navigation {
                top: $cookie-notice-height;
            }

            .cookie-notice {
                top: 0;
            }
        }

        &--hide-secondary {
            height: $primary-nav-height + $survey-banner-height;

            .navigation {

                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    bottom: -.8rem;
                    left: 0;
                    right: 0;
                    height: .8rem;
                    background: $gradient-blue-red;
                }

                &__secondary {
                    display: none;
                }
            }
        }
    }

    .u-is-docked {

        .navigation-fixed-container--fixed-body {
            height: 4.8rem;
        }
    }

    .navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        transition: max-height .3s, top .3s ease-out, transform .3s ease-out;

        &__core {
            display: flex;
            transition: transform .5s;
            position: relative;
            z-index: $level-one;

            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: .4rem;
                box-shadow: 0 .3rem .4rem 0 rgba(0, 0, 0, .25);
            }
        }

        &__secondary {
            transition: transform .5s;
            box-shadow: 0 .3rem .4rem 0 rgba(0, 0, 0, .25);
        }

        &__logo-container {
            background: $secondary-dark;
            display: flex;
            flex-direction: column;
            position: relative;
            min-width: $logo-width;
            text-decoration: none;
            transition: width .2s;
            z-index: $level-three;
        }

        &__logo {
            display: flex;
            flex-grow: 1;
            position: relative;
            width: 100%;
            align-items: center;
            transition: height .2s;

            @include logoAnimation;

            svg {
                width: 100%;
                height: 4rem;
                fill: #fff;
            }
        }

        &__middle {
            border-top: .1rem solid $navigation-border;
            height: $primary-nav-height - $top-slim-nav-height;
            z-index: $level-two;
        }

        &__mobile-logo-container {
            display: none;
        }

        .mobile-menu-close {
            display: none;
        }

        &__primary {
            width: 100%;
            z-index: $level-two;
        }

        &__search {
            position: relative;
        }

        &__top {
            background: $navigation-dark;

            .top-slim-nav {
                width: 100%;
                height: $top-slim-nav-height;
                border-left: .1rem solid $navigation-border;
            }
        }

        &--docked {
            position: fixed;
            width: 100%;
            max-height: $docked-nav-height;
            top: 0;
            left: 0;

            .navigation {

                &__core {
                    transform: translateY(calc((#{$primary-nav-height} * -1) + (#{$survey-banner-height} * -1)));
                }

                &__secondary {
                    transform: translateY(calc((#{$primary-nav-height} * -1) + (#{$survey-banner-height} * -1)));

                    .secondary-navigation {

                        &__section {
                            opacity: 1;
                        }

                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }

        &--docked-midway {
            max-height: 0;

            .navigation {

                &__core {
                    transform: translateY(0);
                }

                &__secondary {
                    transform: translateY(0);

                    .secondary-navigation {

                        &__section {
                            opacity: 0;

                            &--show {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $break-wide) {

        .navigation-fixed-container {
            height: 14.6rem + $survey-banner-height;

            &--hide-secondary {
                height: 13rem;
            }
        }

        .navigation {

            &__logo {
                padding: 0 2.4rem;
            }

            &__logo-container {
                min-width: $logo-break-wide-width;
            }

            &__middle {
                height: $primary-nav-break-wide-height - $top-slim-nav-height;
            }

            &--docked {

                .navigation {

                    &__core,
                    &__secondary {
                        transform: translateY(calc((#{$primary-nav-break-wide-height} * -1 ) + (#{$survey-banner-height} * -1)));
                    }
                }
            }

            &--docked-midway {

                .navigation {

                    &__core {
                        transform: translateY(0);
                    }

                    &__secondary {
                        transform: translateY(0)
                    }
                }
            }
        }
    }

    @media (max-width: $break-narrow) {

        .navigation {

            &__logo {

                svg {
                    height: 3.2rem;
                }
            }

            &__logo-container {
                min-width: $logo-break-narrow-width;
            }

            &__middle {
                height: $primary-nav-break-narrow-height - $top-slim-nav-height;
            }

            &--docked {

                .navigation {

                    &__core,
                    &__secondary {
                        transform: translateY(calc((#{$primary-nav-break-narrow-height} * -1 ) + (#{$survey-banner-height} * -1)));
                    }
                }
            }

            &--docked-midway {

                .navigation {

                    &__core {
                        transform: translateY(0);
                    }

                    &__secondary {
                        transform: translateY(0)
                    }
                }
            }
        }

        .navigation-fixed-container {
            height: 13rem + $survey-banner-height;

            &--hide-secondary {
                height: 11.4rem;
            }
        }
    }

    @media (max-width: $break-small) {

        .navigation {

            &__core::after {
                bottom: -($primary-nav-break-narrow-height - $top-slim-nav-height);
            }

            &__logo-container {
                height: $top-slim-nav-height;
            }

            &__logo svg {
                height: 2.4rem;
            }

            &__middle {
                position: absolute;
                left: 0;
                right: 0;
            }

            &__secondary {
                margin-top: $primary-nav-break-narrow-height - $top-slim-nav-height;
            }
        }
    }
}

/* Default desktop navigation */

@include mq($until: $break-tablet) {

    .navigation-fixed-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: $secondary-dark;
        z-index: $level-six;

        &--mobile-open {
            height: 100%;

            .live-match {
                opacity: 0;
                height: 0;
            }
        }

        &--notice-visible {
            height: $primary-nav-height + $docked-nav-height + $cookie-notice-height;

            &.navigation-fixed-container--mobile-open {
                height: 100%;
            }

            .navigation {
                height: calc(100vh - #{ $cookie-notice-height });
            }

            .main-mobile-navigation {
                top: $cookie-notice-height;
                position: relative;
            }

            .cookie-notice {
                top: 0;
            }
        }
    }

    .main-mobile-navigation {
        background: $secondary-dark;

        &__mobile-logo-container {
            display: block;
            width: 100%;
        }

        &__mobile-logo {
            position: relative;
            z-index: $level-one;
            width: 24rem;
            height: $main-mobile-logo-height;
            left: 50%;
            transform: translateX(-50%);
            transition: left .3s, transform .3s;
            backface-visibility: hidden;

            svg {
                position: absolute;
                top: .6rem;
                left: 0;
                width: 24rem;
                height: 3.2rem;
                fill: #fff;
                transition: height .3s;
            }
        }

        &__quick-nav {
            transform: translateY(0);
            transition: transform .4s;

            .mobile-quick-nav {

                &__item--external {
                    opacity: 1;
                    visibility: visible;
                    transition: opacity .2s, visibility .2s;
                    transition-delay: .15s;
                }
            }
        }

        &--docked {
            height: $main-mobile-logo-height;

            .main-mobile-navigation {

                &__mobile-logo {
                    left: 0;
                    transform: translateX(0%);

                    svg {
                        top: 1rem;
                        left: -2.4rem;
                        fill: #fff;
                        height: 2.4rem;
                    }
                }

                &__quick-nav {
                    transform: translateY(-$main-mobile-logo-height);

                    .mobile-quick-nav {

                        &__item {

                            &--burger-menu {
                                justify-content: flex-end;
                                padding-right: 1.6rem;
                            }

                            &--external {
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                    }
                }
            }
        }
    }

    .navigation {
        display: flex;
        flex-flow: row wrap;
        transform: translateX(110%);
        width: 100vw;
        height: 100%;
        background: $secondary-dark;
        position: absolute;
        top: 0;
        left: 0;
        z-index: $level-two;
        transition: transform .3s;

        &__core,
        &__secondary {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
        }

        &--mobile-open {
            transform: translateX(0);

            .navigation {

                &__logo-container {
                    display: block;
                }

                &__logo {
                    display: none;
                }

                &__middle {
                    position: relative;
                    transition: transform .25s;
                    transform: translateY(0);
                }

                &__primary {
                    display: flex;
                    height: calc(100% - #{$search-container-height} - #{$bottom-user-bar-height} - #{$mobile-padding-removal} + .8rem);
                    margin-top: $search-container-height;
                }

                &__search {
                    opacity: 1;
                    transition: opacity .2s, visibility .2s;
                }

                &__core {
                    z-index: $level-two;
                }

                &__secondary {
                    z-index: $level-one;
                    position: relative;
                    display: flex;
                    height: calc(100% - #{$bottom-user-bar-height} - #{$mobile-padding-removal});

                    .secondary-navigation {

                        &__logo {
                            height: 4.2rem;
                        }
                    }
                }

                &__top {
                    display: block;
                    position: fixed;
                    width: 100vw;
                    bottom: 0;
                    left: 0;
                    height: 4.4rem;
                }
            }
        }

        &--mobile-open.navigation--language-switcher-active {
            background: $grey-light;

            .navigation {

                &__middle,
                &__secondary {
                    transform: translateY(115%);
                }

                &__search {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        &__logo-container {
            display: none;
        }

        &__logo {
            padding: .6rem 0;

            svg {
                width: 100%;
                height: 3.2rem;
            }
        }

        &__middle {
            width: 100%;
            height: 100%;
        }

        &__search {
            z-index: $level-one;
        }

        &__top {
            display: none;
        }
    }
}
