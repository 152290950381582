@mixin footer-tablet {
    padding: 0 .8rem;

    &__constitutional-pattern {
        height: 16rem;
        width: 420%;
        left: -160%;
    }

    &__core {

        &::after {
            content: none;
        }
    }

    &__crest {
        // stylelint-disable-next-line selector-no-qualifying-type
        &.icon {
            width: calc(70% - 1.6rem);
        }
    }

    &__sponsors {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: -.8rem;
            left: -100vw;
            width: 200vw;
            height: 2.4rem;
            z-index: $level-one;
            background: linear-gradient(to bottom, rgba(24, 23, 51, 0), rgba($secondary-dark, 10%));
        }
    }

    &__corporate {
        padding: 1.6rem 1.6rem 2.4rem;
    }

    &::after {
        height: .8rem;
    }
}

.footer {
    background: $secondary-dark;
    position: relative;
    overflow: hidden;
    padding: 0 2.4rem 3rem;
    z-index: $level-three;

    &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.6rem;
        background: $gradient-red-blue;
    }

    &__constitutional-pattern {
        position: absolute;
        top: 0;
        left: -50%;
        width: 200%;
        height: 100%;

        svg {
            width: 100%;
            height: 100%;
            opacity: .6;
        }
    }

    &__core {
        position: relative;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            left: -100vw;
            width: 200vw;
            height: 2.4rem;
            z-index: $level-one;
        }

        &::after {
            bottom: 0;
            background: linear-gradient(to bottom, rgba(24, 23, 51, 0), rgba($secondary-dark, 10%));
        }

        &::before {
            top: 0;
            background: linear-gradient(to top, rgba(24, 23, 51, 0), rgba($secondary-dark, 10%));
        }
    }

    &__corporate {
        padding-top: 5.6rem;
    }

    &__crest {

        &.icon {
            display: inline;
            fill: #fff;
            width: 56.5rem;
            height: auto;
        }
    }

    &__sponsors {
        padding: 2.4rem 0;
    }

    &__title {
        position: relative;
        text-align: center;
        color: $text-light;
        font-size: 4rem;
        text-transform: uppercase;
        @include fcb-bold;
        user-select: none;
        padding: 4rem 0;
    }

    &--narrow {
        @include footer-tablet;
    }
}

@media (max-width: $break-narrow) {

    .footer {
        padding: 0 1.6rem 1rem;

        &__corporate {
            padding-bottom: 1.6rem;
        }
    }
}

@media (max-width: $break-tablet) {

    .footer {
        @include footer-tablet;

        &__title {
            font-size: 3.2rem;
            padding: 5rem 0;
        }
    }
}

@media (max-width: $break-mobile) {

    .footer {
        padding: 0 .4rem;

        &__constitutional-pattern {
            width: 420%;
            left: -160%;
        }

        &__crest {

            &.icon {
                width: calc(70% - 1.6rem);
            }
        }
    }
}
