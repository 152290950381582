.button {
    min-width: 14.8rem;
    position: relative;
    display: inline-block;
    padding: 0 1.6rem;
    line-height: 3.2rem;
    border-radius: 3px;
    border: none;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #fff;
    box-shadow: 0 .2rem .6rem .1rem rgba(0, 0, 0, .2);
    font-size: 1.2rem;
    @include fcb-bold;
    user-select: none;
    will-change: transform;
    transform: translateY(0);
    cursor: pointer;
    pointer-events: all;
    transition: color .2s ease, box-shadow .2s ease, transform .2s ease;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: .3rem;
        z-index: -1;
        backface-visibility: hidden;
    }

    &::before { // bottom layered colour that is revealed on hover
        background: linear-gradient(to left, $primary-brand-blue, $primary-brand-red);
    }

    &::after { // middle layered colour that is faded out on hover to reveal the ::before, this is the initial background-color of the button
        background: linear-gradient(to right, $primary-brand-blue, $primary-brand-red);
        transition: opacity .2s ease;
    }

    .icon {
        width: 1.1rem;
        height: 1.1rem;
        display: inline-block;
        vertical-align: middle;
        margin-top: -.2rem;
        margin-left: .2rem;
        fill: currentColor;
        transition: fill .2s ease;
    }

    @include hover( $disableFocusAtBreakpoint: true ) {
        background-color: transparent; // removes HTML button proprietary background
        box-shadow: 0 .1rem .4rem 0 rgba(0, 0, 0, .5);
        transform: translateY(.1rem);

        &::after { // hides the top layer of the background on an opacity transition, revealing the colour on the ::before element beneath
            opacity: 0;
        }
    }

    &--primary {

        &::after {
            background: $primary-brand-red;
        }
    }

    &--secondary {

        &::after {
            background: $primary-brand-blue;
        }
    }

    &--light {
        color: $text-primary;

        &::after {
            background: #fff;
        }
    }

    &--stroke {
        color: $text-primary;

        &::after { // moves the middle layer in by .2rem to reveal part of the layer beneath, mimicking the appearance of a border
            top: .2rem;
            left: .2rem;
            right: .2rem;
            bottom: .2rem;
            background: #fff;
        }

        @include hover ($disableFocusAtBreakpoint: true) {
            color: #fff;
        }
    }

    &--dark {
        color: #fff;

        &::after {
            background: $secondary-dark;
        }
    }

    &--center {
        margin-left: auto;
        margin-right: auto;
    }

    &--round {
        padding: .9rem 2rem;
        border-radius: 1.6rem;
        line-height: 1.4rem;

        .icon {
            right: 1.6rem;
        }
    }

    &--standard-tickets {
        color: $text-primary;

        &::before {
            background: $primary-brand-red;
        }

        &::after {
            background: $primary-brand-yellow;
        }

        @include hover ($disableFocusAtBreakpoint: true) {
            color: #fff;
        }

        &.button--ticket-disabled {
            color: #e7b809;

            &::before {
                background: $primary-brand-yellow;
            }
        }
    }

    &--ticket-disabled {
        pointer-events: none;

        @include hover {
            transform: translateY(0);
            cursor: default;
            box-shadow: 0 .2rem .6rem .1rem rgba(0, 0, 0, .2);
        }
    }

    &--group-tickets {
        color: $text-secondary;
        box-shadow: 0 .2rem .6rem .1rem rgba(0, 0, 0, .12);

        &::before {
            background: #727272;
        }

        &::after {
            background: $grey-light;
        }

        .icon {
            width: 1.8rem;
            margin: 0 .2rem 0 0;
        }

        @include hover ($disableFocusAtBreakpoint: true) {
            color: #fff;
        }

        &.button--ticket-disabled {
            color: $text-tertiary;
            box-shadow: 0 .2rem .6rem .1rem rgba(0, 0, 0, .12);

            &::before {
                background: $grey-light;
            }
        }
    }

    &--vip-tickets-on-dark {

        &::before {
            background: #000;
            border: 1px solid $vip-primary;
        }

        &::after {
            background: $vip-button-gradient;
        }

        @include hover ($disableFocusAtBreakpoint: true) {
            color: $vip-primary;
        }

        &.button--ticket-disabled {
            color: $vip-primary;
            opacity: .4;

            &::before {
                background: $vip-button-gradient;
                border: none;
            }
        }
    }

    &--vip-tickets-on-light {
        color: $vip-primary;

        &::before {
            background: $vip-button-gradient;
        }

        &::after {
            background: #000;
            border: 1px solid $vip-primary;
        }

        @include hover ($disableFocusAtBreakpoint: true) {
            color: #fff;
        }

        &.button--ticket-disabled {
            color: $vip-primary;
            opacity: .3;

            &::before {
                background: #000;
                border: 1px solid $vip-primary;
            }
        }
    }

    &--gold {
        color: $text-light;

        &::before {
            background: #fff;
        }

        &::after {
            background: $vip-button-gradient;
        }

        @include hover ($disableFocusAtBreakpoint: true) {
            color: $text-primary;
        }
    }

    &--icon {
        padding: 1rem 4rem;
    }

    &--translucent {
        color: $text-light;

        &::before {
            background: $secondary-dark;
        }

        &::after {
            background: rgba($secondary-dark, .4);
        }
    }

    &--large {
        min-width: 30rem;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        font-size: 1.2rem;
        line-height: 1.2rem;

        .icon {
            width: 1.3rem;
            height: 1.3rem;
        }
    }

    &--disabled {
        background: #fff;
        color: rgba($text-secondary, .2);
        box-shadow: none;
        cursor: not-allowed;
        pointer-events: none;

        &::before {
            background: none;
        }

        &::after {
            background: #fff;
            border: .1rem solid $grey;
        }

        @include hover {
            box-shadow: none;
            transform: none;
        }
    }

    &--on {

        &--dark {

            &::after {
                background: $primary-brand-red;
            }
        }
    }

    &--transparent {
        box-shadow: none;

        &::before,
        &::after {
            background: transparent;
        }

        @include hover( $disableFocusAtBreakpoint: true ) {
            background-color: transparent;
            box-shadow: none;
            transform: none;

            &::after {
                opacity: 1;
            }
        }
    }

    &--italic {
        font-style: italic;
    }
}
