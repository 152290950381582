@include mq($from: $break-tablet) {

    .mobile-quick-nav {
        display: none;
    }
}

@include mq($until: $break-tablet) {

    .mobile-quick-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4.4rem;
        background: $gradient-red-blue;
        opacity: 1;
        z-index: $level-two;
        transition: opacity .15s;

        &__item {
            flex: 1 0 auto;
            color: $text-light;
            position: relative;
            justify-content: center;
            text-align: center;
            line-height: 4.4rem;
            font-size: 1.6rem;

            &::before {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                background-color: rgba(255, 255, 255, .25);
                width: .1rem;
                height: 2.4rem;
                transform: translateY(-50%);
            }

            &:last-of-type {

                &::before {
                    content: none;
                }
            }

            &--burger-menu {
                position: relative;
                display: flex;
                align-items: center;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                text-decoration: none;
                color: $text-light;
            }

            .mobile-quick-nav__icon {
                fill: #fff;
                margin-right: .8rem;
                width: 2.4rem;
                height: 2.4rem;

                &--external {
                    width: 1rem;
                    height: 1rem;
                    margin: .2rem 0 0 .6rem;
                }
            }

            .burger-menu {
                width: 2rem;
                margin-right: .8rem;

                &__icon {
                    height: .2rem;
                    background: #fff;
                    border-radius: .1rem;

                    &--middle {
                        position: relative;
                        margin-top: .4rem;
                        margin-bottom: .4rem;
                    }
                }
            }
        }
    }

    @media (max-width: $break-mobile) {

        .mobile-quick-nav {

            &__item {
                font-size: 1.2rem;
            }
        }
    }
}
