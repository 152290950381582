@include mq($from: $break-tablet) {

    .language-switcher {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 1.6rem;
        border-right: .1rem solid $navigation-border;
        background-color: $navigation-dark;
        transition: background-color .3s;
        @include fcb-semi-bold;

        @include hover {
            background-color: $navigation-border;
        }

        /* Add additional hover with no focus to prevent dropdown opening on keyboard focus */
        @include hover ($break-tablet, false) {

            .language-switcher__dropdown {
                opacity: 1;
                transform: scale(1);

                > li {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        &__active-language {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;

            .language-switcher {

                &__text {
                    color: $text-light;
                }
            }
        }

        &__close-container {
            display: none;
        }

        &__dropdown {
            @include dropdown();
            top: 3.6rem;
            padding-bottom: 1.6rem;
            min-width: 24rem;
            left: auto;
            right: 0;
            z-index: $level-four;
            @include dropdownHover();
            @include dropdownTriangle();

            &--visible {
                opacity: 1;
                transform: scale(1);

                > li {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        &__flag {
            margin-right: .7rem;

            .icon {

                &--desktop {
                    display: inline-block;
                }

                &--mobile {
                    display: none;
                }
            }
        }

        &__heading {
            position: relative;
            left: .5rem;
            margin-top: 1.6rem;
            margin-bottom: .8rem;
            margin-left: 2.4rem;
            @include fcb-light;
            font-size: 1.2rem;
            height: 1.4rem;
            line-height: 1.4rem;
            color: #767676;
        }

        &__icon {

            &.icon {
                width: .8rem;
                height: .8rem;
                align-self: center;
                fill: #fff;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            border-bottom: .1rem solid rgba(0, 0, 0, .03);
            background-color: transparent;
            transition: background-color .3s;
            color: $text-primary;

            @include hover {
                background-color: $grey;
            }

            &--active {

                &::before {
                    display: block;
                    position: absolute;
                    left: 0;
                    content: '';
                    height: 2.4rem;
                    width: .8rem;
                    background-color: $primary-brand-blue;
                }
            }
        }

        &__item-link {
            display: inline-block;
            width: 100%;
            padding: .8rem 2.8rem;
            text-decoration: none;
        }

        &__text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: 1.4rem;
            margin-right: .4rem;
            color: $text-primary;
            text-decoration: none;
        }

        &__viewing {
            @include fcb-light;
            color: #767676;
            font-size: 1rem;
        }
    }

    @media (max-width: $break-wide) {

        .language-switcher {

            &__flag {

                .icon {
                    width: 1.24rem;
                    height: 1.24rem;
                }
            }

            &__heading,
            &__text {
                font-size: 1.2rem;
            }
        }
    }

    @media (max-width: $break-narrow) {

        .language-switcher {

            &__active-language {

                .language-switcher__text {
                    display: none;
                }
            }

            &__heading,
            &__text {
                font-size: 1.04rem;
            }
        }
    }
}

@include mq($until: $break-tablet) {

    .language-switcher {
        position: relative;
        display: flex;
        align-items: center;
        background-color: transparent;
        transition: background-color .3s;
        @include fcb-semi-bold;
        margin-right: .8rem;
        height: 3.2rem;

        &__active-language {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: auto;
            height: 100%;
            padding: 0 1.6rem;
            padding-left: 1rem;
            border-left: .1rem solid rgba(255, 255, 255, .15);

            @media (max-width: 360px) {
                padding: 0 .6rem;
            }

            .language-switcher__icon {
                transform: rotate(180deg);
            }
        }

        &__close-container {
            display: flex;
        }

        &__dropdown {
            position: absolute;
            top: 4.4rem;
            right: -.1rem;
            width: 100vw;
            height: 100vh;
            background: #fff;
            transform: translateY(30vh);
            transition: transform .3s;
            z-index: $level-three;
        }

        &__flag {

            .icon {

                &--desktop {
                    display: none;
                }

                &--mobile {
                    display: inline-block;
                }
            }

            svg {
                fill: #fff;
                margin-right: .8rem;
            }
        }

        &__heading {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            left: .5rem;
            margin-top: 1.6rem;
            margin-bottom: .8rem;
            margin-left: 2.4rem;
            @include fcb-light;
            font-size: 1.2rem;
            height: 2.4rem;
            line-height: 2.4rem;
            color: #767676;
        }

        &__icon {
            width: .8rem;
            height: .8rem;
            fill: #fff;
        }

        &__item {
            display: flex;
            align-items: center;
            border-bottom: .1rem solid rgba(0, 0, 0, .03);
            background-color: transparent;
            transition: background-color .3s;

            @include hover {
                background-color: $grey;
            }

            &--active {

                &::before {
                    display: block;
                    position: absolute;
                    left: 0;
                    content: '';
                    height: 2.4rem;
                    width: .8rem;
                    background-color: $primary-brand-blue;
                }
            }
        }

        &__item-link {
            display: inline-block;
            width: 100%;
            padding: .8rem 2.8rem;
            text-decoration: none;
        }

        &__text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.2rem;
            margin-right: .6rem;
            text-decoration: none;
            color: $text-light;
        }

        &__viewing {
            @include fcb-light;
            color: #767676;
            font-size: 1rem;
            margin-left: 1.6rem;
        }
    }

    .navigation--language-switcher-active {

        .language-switcher {

            &__close-container {
                opacity: 1;
                transition-delay: .6s;
            }

            &__dropdown {
                transform: translateY(calc(-100% + #{$bottom-user-bar-height})); // move it back on screen, and factor in bottom height
            }

            &__text {
                color: $text-primary;
            }
        }
    }
}

@media (max-width: 420px) {

    .language-switcher {
        padding: 0 .2rem;

        &__active-language {
            justify-content: center;
            width: 100%;
            padding-left: .4rem;
        }
    }
}
