$sponsor-padding: 4.2rem;
$sponsor-container-padding: 1.6rem;

//stylelint-disable selector-max-type
.footer-sponsors {
    display: flex;
    width: 100%;
    position: relative;

    &__container {
        display: flex;
        width: 100%;
    }

    &__link-wrapper {
        position: absolute;
        top: 0;
        right: 1.6rem;
        min-width: 13.2rem;
        z-index: 20;
    }

    .footer-sponsors {

        &__sponsor {
            height: 8rem;

            svg {
                width: 100%;
                height: auto;
            }
        }

        &__sponsors-container {
            justify-content: space-around;
        }
    }

    &__main {
        flex: 3;

        /* first team */
        &--two-sections {

            .footer-sponsors {

                &__sponsor {

                    &--nike {
                        width: 22%;
                    }

                    &--rakuten {
                        width: 23%;

                        svg {
                            margin-top: -5%;
                        }
                    }

                    &--beko {
                        width: 17%;

                        svg {
                            margin-top: 7.5%;
                        }
                    }
                }
            }
        }

        /* other teams */
        &--one-section {

            .footer-sponsors {

                &__sponsor {

                    &--nike {
                        width: 11%;
                    }

                    &--stanley {
                        width: 14%;

                        svg {
                            margin-top: -2%;
                        }
                    }

                    &--serveto {
                        width: 10%;

                        svg {
                            margin-top: -2%;
                        }
                    }

                    &--lassa {
                        width: 15%;
                    }
                }
            }
        }
    }

    &__premium {
        flex: 4;

        /* first team */
        &--two-sections {

            .footer-sponsors {

                &__sponsor {

                    &--caixa-bank {
                        width: 10%;

                        svg {
                            margin-top: -15%;
                        }
                    }

                    &--estrella {
                        width: 18%;

                        svg {
                            margin-top: -10%;
                        }
                    }

                    &--gillette {
                        width: 14%;

                        svg {
                            margin-top: -10%;
                        }
                    }

                    &--pes {
                        width: 18%;

                        svg {
                            margin-top: -7.5%;
                        }
                    }
                }
            }
        }

        /* other teams */
        &--one-section {

            .footer-sponsors {

                &__sponsor {

                    svg {
                        width: 100%;
                        height: auto;
                    }

                    &--nike {
                        width: 22%;
                    }
                }
            }
        }
    }

    &__main,
    &__premium {
        position: relative;
        padding-top: .8rem;
    }

    &__sponsors-container {
        display: flex;
        white-space: nowrap;
        align-items: center;
        position: relative;
        padding: 0 $sponsor-container-padding;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: .2rem;
            height: 100%;
            background-color: $primary-brand-yellow;
        }
    }

    &__sponsor {
        height: 100%;
        margin: 1.6rem .8rem;

        a {
            line-height: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;

            &::after {
                display: block;
                position: absolute;
                content: '';
                bottom: -1.6rem;
                left: 51%;
                right: 50%;
                height: .4rem;
                background-color: $primary-brand-yellow;
                transition: left .2s ease, right .2s ease;
            }

            @include hover {

                &::after {
                    left: 0;
                    right: 0;
                }
            }
        }

        svg {
            max-width: 22rem;
            max-height: 9.6rem;
        }
    }

    &__title {
        @include fcb-bold;
        font-size: 1.2rem;
        height: 1.4rem;
        margin-bottom: 1.1rem;
        line-height: 1.4rem;
        letter-spacing: .1rem;
        text-transform: uppercase;
        color: $primary-brand-yellow;
    }
}

@mixin footer-sponsors-tablet {

    &__container {
        flex-direction: column;
    }

    &__link {
        display: block;
        width: 100%;
    }

    &__link-wrapper {
        bottom: -2.4rem;
        top: auto;
        left: 50%;
        width: calc(100% - 3.2rem);
        text-align: center;
        margin: 0 auto;
        transform: translateX(-50%);
    }

    &__main,
    &__premium {
        padding: 0 $sponsor-container-padding;
    }

    &__sponsors-container {
        margin: 0;
        padding: 2.4rem 0;

        &::before {
            content: none;
        }
    }

    &__title {
        width: 100%;
        text-align: center;
        line-height: .2rem;
        height: auto;

        span {
            padding: 0 .8rem;
            position: relative;

            &::before,
            &::after {
                content: '';
                position: absolute;
                display: block;
                top: 50%;
                background: $primary-brand-yellow;
                width: calc(50vw - 50% - 3.2rem);
                height: .2rem;
            }

            &::before {
                left: calc(-50vw + 50% + #{$sponsor-container-padding});
            }

            &::after {
                right: calc(-50vw + 50% + #{$sponsor-container-padding});
            }
        }
    }
}

.footer--narrow {

    .footer-sponsors {
        @include footer-sponsors-tablet;
    }
}

@media (max-width: $break-small) {

    .footer-sponsors {

        &__main {
            min-height: 14rem;

            &--one-section {

                .footer-sponsors {

                    &__sponsor {

                        &--nike {
                            width: 15%;
                        }

                        &--stanley {
                            width: 17%;
                        }

                        &--serveto {
                            width: 13%;

                            svg {
                                margin-top: 1%;
                            }
                        }

                        &--lassa {
                            width: 18%;
                        }
                    }
                }
            }
        }

        &__premium {
            min-height: 13rem;
        }
    }
}

@media (max-width: $break-tablet) {

    .footer-sponsors {
        @include footer-sponsors-tablet;

        &__main {
            min-height: 14rem;

            &--two-sections {

                .footer-sponsors {

                    &__sponsor {
                        height: 4rem;

                        &--nike {
                            width: 17%;
                        }

                        &--rakuten {
                            width: 18%;
                        }

                        &--beko {
                            width: 14%;
                        }
                    }
                }
            }

            &--one-section {

                .footer-sponsors {

                    &--nike {
                        width: 22%;
                    }

                    &--rakuten {
                        width: 23%;

                        svg {
                            margin-top: -5%;
                        }
                    }

                    &--beko {
                        width: 17%;

                        svg {
                            margin-top: 7.5%;
                        }
                    }
                }
            }
        }

        &__premium {
            min-height: 13rem;

            &--two-sections {

                .footer-sponsors {

                    &__sponsor {
                        height: 4rem;

                        &--nike {
                            width: 17%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $break-phablet) {

    .footer-sponsors {

        &__main {
            min-height: 14rem;

            &--one-section {

                .footer-sponsors {

                    &__sponsor {
                        height: 4rem;

                        &--nike {
                            width: 20%;
                        }

                        &--stanley {
                            width: 22%;

                            svg {
                                margin-top: -2%;
                            }
                        }

                        &--serveto {
                            width: 18%;
                        }

                        &--lassa {
                            width: 23%;
                        }
                    }
                }
            }
        }

        &__premium {
            min-height: 13rem;

            &--one-section {

                .footer-sponsors {

                    &__sponsor {
                        height: 4rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 470px) {

    .footer-sponsors {

        &__main {
            min-height: 9rem;

            .footer-sponsors__sponsors-container {
                padding: 0;
            }
        }

        &__premium {
            min-height: 10rem;

            .footer-sponsors__sponsors-container {
                padding: 0;
            }
        }

        &__sponsor a::after {
            display: none;
        }
    }
}
//stylelint-enable selector-max-type
