.mobile-menu-close {
    position: absolute;
    top: 0;
    left: calc(100vw - 7.9rem);
    padding: 1.3rem 1.8rem 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__icon {
        width: 1.6rem;
        height: 1.6rem;
        margin-bottom: .3rem;
        fill: #fff;
    }

    &__text {
        @include fcb-bold;
        text-transform: uppercase;
        font-size: 1.4rem;
        color: $text-light;
    }
}